<template>
 <div id="OfficialAccount" :style="{backgroundImage:`url(${bgImg})`}">
    <div class="content">
      <img class="headerimg" src="@/assets/img/ofa/ofa-header.png" alt="">
      <div class="flex align-center justify-center">
        <img class="iconimg" src="@/assets/img/ofa/ofa-icon.png" alt="">
        <p class="icontxt">大学生的掌上云服务平台传播青春校园文化正能量</p>
      </div>
      <img class="qrdodeimg" src="@/assets/img/ofa/ofa-qrdode.png" alt="">
      <div class="handimg" >
        <img src="@/assets/img/ofa/ofa-hand.png" alt="">
      </div>
      <p>请先关注<span class="red_color">大学掌公众号</span></p>
      <p>长按识别二维码</p>
    </div>
 </div>
</template>

<script>
export default {
  name: 'officialAccount',
  components: {
  },
  data () {
    return {
      bgImg: require("@/assets/img/ofa/ofa-bg.png"),
    };
  },
  mounted () {},
  created () {
  },
  methods: {
  }
};
</script>
<style scoped  lang="scss">
</style>
<style>
</style>
